const validate = values => {
    const errors = {}
    const requiredFields = [
        'type',
        'title'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Полето е задължително'
        }
    })
    
    return errors
}

export default validate