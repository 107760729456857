export function authHeader() {
    try {
        // return authorization header with jwt token
        let user = JSON.parse(localStorage.getItem('loggedUser'));
    
        if (user && user.token) {
            return {
                'Authorization': 'Bearer ' + user.token,
                'Content-Type': 'application/json'
            };
        } else {
            return { 'Content-Type': 'application/json' };
        }
    } catch (err) {
        return { 'Content-Type': 'application/json' };
    }
}