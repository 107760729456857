import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getLoggedUser } from './';


export function SignedInRoute({ component: Component, ...rest }) {
    const loggedUser = getLoggedUser();
    return (

        <Route {...rest} render={props => (
            (loggedUser && loggedUser.token)
                ? <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                : <Component {...props} />
        )} />
    )
}