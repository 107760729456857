import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { contentActionCreators } from '../actions/contentActionCreators'
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
    card: {
        marginBottom: 24,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -50,
            marginRight: -50
        }
    },
    heading: {
        color: theme.palette.primary.light,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -50
        }
    },
});

class News extends Component {

    componentWillMount() {
        this.props.requestAllNews();
    }

    render() {

        return (
            <div>{this.props.isLoading === false ? NewsElements(this.props) : <CircularProgress />}</div>
        )
    }
}

function NewsElements(props) {
    const { classes } = props;

    return (
        <div>
            <Typography variant="h4" gutterBottom className={classes.heading}>
                Новини
                </Typography>
            {props.news.length > 0 ? props.news.map(article =>
                <Card key={article.id} className={classes.card}>
                    <div>
                        <LinkContainer to={`/article/${article.id}`}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.media}
                                    title={article.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5">
                                        {article.title}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </LinkContainer>

                        <CardActions>
                            <LinkContainer to={`/article/${article.id}`} exact>
                                <Button size="small" color="primary">
                                    Прочети още >>>
                                </Button>
                            </LinkContainer>
                        </CardActions>
                    </div>
                </Card>
            ) : <div>Няма намерени новини.</div>
            }
        </div>
    );
}

export default withStyles(styles)(connect(
    state => state.content,
    dispatch => bindActionCreators(contentActionCreators, dispatch)
)(News));
