import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { HowToReg, Work, SupervisorAccount, TransferWithinAStation } from '@material-ui/icons';
import { profileActionCreators } from '../actions/profileActionCreators';
import { jobsActionCreators } from "../actions/jobsActionCreators";

const styles = theme => ({
    root: {
        //backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
    }
});



class Statistic extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getStudentCount();
        this.props.getEmployersCount();
        this.props.getOffersCount();
    }

    render() {
        const { classes } = this.props;


        return (
            <Paper className={classes.root}>
                <Typography variant="h6" gutterBottom>
                    МЦРК в числа
            </Typography>
                <List component="nav">
                    <ListItem button>
                        <Avatar>
                            <HowToReg />
                        </Avatar>
                        <ListItemText primary="Регистрирани студенти" secondary={this.props.countStudents > 0 ? this.props.countStudents : ''} secondaryTypographyProps={{ variant: 'headline' }} />
                    </ListItem>
                    <Divider />
                    <ListItem button divider>
                        <Avatar>
                            <TransferWithinAStation />
                        </Avatar>
                        <ListItemText primary="Работодатели" secondary={this.props.countEmployers > 0 ? this.props.countEmployers : ''} secondaryTypographyProps={{ variant: 'headline' }} />
                    </ListItem>
                    <ListItem button>
                        <Avatar>
                            <Work />
                        </Avatar>
                        <ListItemText primary="Обяви за работа и стаж" secondary={this.props.countOffers > 0 ? this.props.countOffers : ''} secondaryTypographyProps={{ variant: 'headline' }} />
                    </ListItem>
                    <Divider light />

                    
                </List>
            </Paper>
        );
    }
}

Statistic.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
    connect(
        state => { return { ...state.profiles, ...state.jobs } },
        dispatch => bindActionCreators(
            { ...profileActionCreators, ...jobsActionCreators},
            dispatch)
    )(Statistic)
);