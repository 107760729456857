export function notify({message, status}){
    return{
        type: "ENQUEUE_SNACKBAR",
        notification: {
            key: new Date().getTime() + Math.round(Math.random()*100),
            message: message,
            options: {
                variant: status==200?'success':(status==400?'error':'info'),
            },
        }
    }
}