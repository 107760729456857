import React, { Fragment } from 'react';
import Dropzone from 'react-dropzone';
import { FormHelperText, Grid } from '@material-ui/core';
import { getBlobFromBase64 } from './getBlobFromBase64';

function previewImage(files, elementId) {
    let file = files[0];
    var reader = new FileReader();

    reader.onload = function () {
        if (document.getElementById(elementId)) {

            document.getElementById(elementId).src = reader.result;
        }
    };

    reader.readAsDataURL(file);    
};

export const renderSingleDropzoneImageField = (field) => {
    let files = field.input.value;

    if (typeof files === 'string') {
        let fileToBlob = getBlobFromBase64(files);
        let blobArr = [fileToBlob];
        files = blobArr;
    }

    if (files.length > 0) {
        previewImage(files, 'company-logo');
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={6}>
                <Dropzone
                    name={field.name}
                    accept="image/jpg,image/jpeg,image/png"
                    multiple={false}
                    onDrop={(filesToUpload, e) => field.input.onChange(filesToUpload)}
                >
                    <div>Привлачете вашата снимка тук, или кликнете за да селектирате файла директно. Позволените формати са: .jpg, .jpeg, .png</div>
                </Dropzone>
            </Grid>
            <Grid item xs={12} sm={6}>
                {files && Array.isArray(files) && (
                    <Fragment>
                        {files.map((file, i) => <img alt="Company Logo here" style={{ height: 200 }} id="company-logo" key={file.name} src={file} />)}
                    </Fragment>
                )}                              
            </Grid>
        </Grid>
    );
}