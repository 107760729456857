import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Paper, withStyles, List, ListItem, ListItemText, Chip, CircularProgress, Button } from '@material-ui/core';
import { LinkContainer } from 'react-router-bootstrap';
import { jobsActionCreators } from '../../actions/jobsActionCreators';
import Moment from 'react-moment';
import { CalendarToday } from '@material-ui/icons';

const styles = theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },
    paper: {
        padding: theme.spacing.unit * 2,
        overflow: 'hidden',
        textAlign: 'justify',
    },
    title: {
        fontSize: '1.2rem',
    },
    coverImage: {
        width: '100%',
    },
    socialShare: {
        color: theme.palette.primary.main,
    },
    scholarshipBackground: {
        backgroundColor: '#5E09DD',
        color: '#fff'
    },
    listItem: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'stretch'
        }
    }
});

class ScholarshipsList extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        this.props.requestScholarshipOffers();
        this.props.getCategories();
    }

    handleSubmit() {
        this.props.filterScholarshipOffers();
    }

    render() {
        const { classes } = this.props;
        const jobs = this.props.jobOffers;  
        const employer = this.props.employer;

        return (this.props.isLoading ? <div><CircularProgress size={100} /></div> : 
            <div className={classes.root}>
                <h2>Стипендии</h2>
                <Grid container spacing={24}>  
                    <Grid item xs={12} align="right">
                        
                    </Grid>
                    <Grid item xs={12}>                        
                        <Paper className={classes.paper}>
                            <List dense>
                                {jobs && jobs.length > 0 ? jobs.map((currentJob, i) =>
                                    <LinkContainer key={currentJob.id} to={`/job/details/${currentJob.id}`} exact>
                                        <ListItem button className={classes.listItem}>
                                            <Chip label="стипендия" className={classes.scholarshipBackground} />
                                            <ListItemText primary={currentJob.title} className={classes.title} />
                                            <Chip
                                                icon={<CalendarToday />}
                                                label={<Moment format="DD.MM.YYYY" withTitle>{currentJob.expirationDate}</Moment>}
                                            />
                                        </ListItem>
                                    </LinkContainer>)
                                    : <ListItem>
                                        Няма налични обявления за стипендии
                                    </ListItem>}
                            </List>
                        </Paper>
                    </Grid>                   
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(connect(
    state => state.jobs,
    dispatch => bindActionCreators(jobsActionCreators, dispatch)
)(ScholarshipsList));
