import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { contentActionCreators } from "../../actions/contentActionCreators";
import { withStyles } from "@material-ui/core";
import ArticleForm from "./forms/ArticleForm";
import { getLoggedUser } from "../../helpers";

const styles = theme => ({});

class AddArticle extends Component {
	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleImageDrop = this.handleImageDrop.bind(this);
	}

	handleSubmit = e => {
        this.props.submitPublication(this.props.articleToRead.imagesNames);
	};

	handleImageDrop = (e, filesToUpload) => {
		this.props.uploadImages(filesToUpload);
	};

	render() {
        const user = getLoggedUser();
        if(user == null) return (null);
        const userId = user.id;
        const article = this.props.articleToRead;

		return (
			<div>
				<h3>Публикуване на новина</h3>
				<ArticleForm
					onSubmit={this.handleSubmit}
					onDrop={this.handleImageDrop}
                    userId={userId}
                    article={article}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(
	connect(
		state => state.content,
		dispatch => bindActionCreators(contentActionCreators, dispatch)
	)(AddArticle)
);
