import { menuConstants } from "../constants/menuConstants";
import { contentConstants } from "../constants/contentConstants";
import { config } from "../helpers/config";
import { authConstants } from "../constants/authenticationConstants";

export const menuActionCreators = {
	requestMenuElements: key => async dispatch => {
		const url = `${config.base}api/menu/?key=${key}`;
		try {
			const response = await fetch(url);
			const elements = await response.json();
			dispatch({
				type: menuConstants.REQUEST_MENU_ELEMENTS,
				elements: elements
			});
		} catch (ex) {}
	},

	requestSubmenuElements: articleId => async dispatch => {
		const url = `${config.base}api/menu/byarticle/${articleId}`;
		const response = await fetch(url);
		const submenuElements = await response.json();

		dispatch({
			type: menuConstants.REQUEST_SUBMENU_ELEMENTS,
			submenuElements: submenuElements
		});
	},

	searchNews: searchTerm => async dispatch => {
		const url = `${config.base}api/Content/SearchNews?searchTerm=${searchTerm}`;
		const response = await fetch(url);
		const news = await response.json();
		dispatch({
			type: contentConstants.REQUEST_NEWS,
			news
		});
	},

	searchEvents: searchTerm => async dispatch => {
		const url = `${config.base}api/Content/SearchEvents?searchTerm=${searchTerm}`;
		const response = await fetch(url);
		const events = await response.json();
		dispatch({
			type: contentConstants.REQUEST_EVENTS,
			events
		});
    },

	removeSearch: () => async dispatch => {
		const newsUrl = `${config.base}api/Content/news`;
		const newsResponse = await fetch(newsUrl);
		const news = await newsResponse.json();
		const eventsUrl = "api/Content/events";

		const eventsResponse = await fetch(eventsUrl);
		const events = await eventsResponse.json();

		dispatch({
			type: contentConstants.REQUEST_EVENTS,
			events
		});
		dispatch({
			type: contentConstants.REQUEST_NEWS,
			news
		});
    },
};
