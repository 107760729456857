export const profilesConstants = {
    REQUEST_EMPLOYER: 'REQUEST_EMPLOYER',
    REQUEST_EMPLOYER_BEGIN: 'REQUEST_EMPLOYER_BEGIN',
    REQUEST_STUDENT: 'REQUEST_STUDENT',
    REQUEST_STUDENT_BEGIN: 'REQUEST_STUDENT_BEGIN',
    REQUEST_LOGS_BEGIN: 'REQUEST_LOGS_BEGIN',
    REQUEST_EMPLOYERS: 'REQUEST_EMPLOYERS',
    REQUEST_LOGS: 'REQUEST_LOGS',
    REQUEST_EMPLOYERS_BEGIN: 'REQUEST_EMPLOYERS_BEGIN',
    REQUEST_ALLSTUDENTS: 'REQUEST_ALLSTUDENTS',
    REQUEST_ALLSTUDENTS_BEGIN: 'REQUEST_ALLSTUDENTS_BEGIN',
    EDIT_SUCCESS: 'EDIT_SUCCESS',
    EDIT_ERROR: 'EDIT_ERROR',
    EDIT_STUDENT_SUCCESS: 'EDIT_STUDENT_SUCCESS',
    STATISTICS: 'STATISTICS',
    STATISTICS_EMP: 'STATISTICS_EMP'
}