import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { LinkContainer } from 'react-router-bootstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
//import Loadable from 'react-loadable';
import { Update, Done, SpeakerNotes, People, SupervisedUserCircle, Description, Folder, ChevronLeft, ChevronRight } from '@material-ui/icons';

const drawerWidth = '100%';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer ,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },

});

class Submenu extends Component {
    state = {
        open: true,
    };
    componentWillMount() {

    }

    render() {
        const props = this.props;
        const { classes } = this.props;
        /*const icons = props.elements.map(x => Loadable({
            loader: () => import('@material-ui/icons/' + x.icon),
            loading: () => <CircularProgress/>
        }));*/
        //let i = 0;
        const icons = {
            'Update': <Update />,
            'Done': <Done />,
            'SpeakerNotes': <SpeakerNotes />,
            'People': <People />,
            'SupervisedUserCircle': <SupervisedUserCircle/>,
            'Description': <Description />,
            'Folder': <Folder />,
        };

        return (
            <div>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classNames(classes.drawerPaper),
                    }}
                    open={this.state.open}
                >
                    <Divider />

                    {props.elements.map((el) =>
                        <LinkContainer to={el.articleId != null ? `/article/${el.articleId}` : el.link}>
                            <ListItem button>
                                <ListItemIcon>
                                    {icons[el.icon]}                                    
                                </ListItemIcon>
                                <ListItemText primary={el.title} />
                            </ListItem>
                        </LinkContainer>
                    )}
                    <Divider />
                </Drawer>
            </div>
        );
    }
}

function Module(icons, i) {
    let Module = icons[i];
    return <Module key={i} />
}

export default withStyles(styles)(Submenu);