import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { renderCheckbox } from '../../../helpers';

const styles = theme => ({
    margin: {
        margin: theme.spacing.unit,
    }
});

class FilterCategoriesForm extends Component {

    render() {
        const { handleSubmit, categories } = this.props;

        let categoriesConverted = [];

        for (var i in categories) {
            let currCategory = { label: categories[i].name, value: categories[i].id }
            categoriesConverted.push(currCategory);
        }

        return (
            <div>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={6}>    
                            <Field
                                name="jobCategories"
                                component={renderCheckbox}
                                margin="dense"
                                options={categoriesConverted}
                            />
                            <br />
                            <Grid>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                >
                                    Филтрирай
                                </Button>
                            </Grid>
                        </Grid>                        
                    </Grid>
                </form>
            </div>            
        );
    }
}


export default withStyles(styles)(reduxForm({
    form: 'FilterCategoriesForm', // a unique identifier for this form
    enableReinitialize: false
})(FilterCategoriesForm));