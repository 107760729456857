import React from 'react';
import Dropzone from 'react-dropzone';
import { Grid, Button } from '@material-ui/core';
import { LinkContainer } from 'react-router-bootstrap';

export const renderDropzoneDocField = (field) => {
    const files = field.input.value;
    const cv = Array.isArray(files) ? files[0].name : field.cvName;

    return (
        <Grid container>
            <Grid item xs={12} sm={6}>
                <Dropzone
                    name={field.name}
                    accept=".pdf,.doc,.docx"
                    onDrop={(filesToUpload, e) => field.input.onChange(filesToUpload)}
                >
                    <div>Привлачете файла с вашата автобиография тук, или кликнете за да го изберете директно.
                        Позволени файлови формати: .pdf, .doc, .docx</div>
                </Dropzone>
                {field.meta.error &&
                    field.meta.touched &&
                    <span className="error">{field.meta.error}</span>}
            </Grid>
            <Grid item xs={12} sm={6}>
                {files && !Array.isArray(files) && cv && (
                    <div>
                        <li>{cv}</li>
                        <br />
                        <a href={`/uploads/cvs/${cv}`} download>
                            <Button variant="contained" color="primary">
                                Свали
                            </Button>
                        </a>
                    </div>
                )}
                {files && Array.isArray(files) &&
                    (<ul>{files.map((file, i) => <li key={i}>{file.name}</li>)}</ul>)}
            </Grid>
        </Grid>
    );
}