import { jobsConstants } from "../constants/jobsConstants";

const initialState = {
	isLoading: false,
	isUploading: false,
	employer: {},
    categories: [],
    countOffers: 0,
	currentOffer:{
        imagesNames: [],
        hasCurrentStudentApppliedFor: false
    },
	jobOffers: []
};

export const reducer = (state, action) => {
	state = state || initialState;

	switch (action.type) {
		case jobsConstants.REQUEST_CATEGORIES:
			return {
				...state,
				categories: action.categories
			};
		case jobsConstants.REQUEST_JOBOFFERS_BEGIN:
			return {
				...state,
				isLoading: true
			};
		case jobsConstants.REQUEST_JOBOFFERS:
			return {
				...state,
				isLoading: false,
				jobOffers: action.jobOffers
			};
		case jobsConstants.REQUEST_OFFERDETAILS_BEGIN:
			return {
				...state,
				isLoading: true
			};
		case jobsConstants.REQUEST_OFFERDETIALS:
			return {
				...state,
				isLoading: false,
				currentOffer: action.currentOffer
            };
        case jobsConstants.REQUEST_EMPLOYER_BEGIN:
            return {
                ...state,
                isLoading: true
            };
		case jobsConstants.REQUEST_EMPLOYER:
			return {
                ...state,
                isLoading: false,
				employer: action.employer
			};
		case jobsConstants.POST_SUCCESS:
			return {
				...state
			};
		case jobsConstants.APPLY_SUCCESS:
			return {
				...state,
				currentOffer: action.currentOffer
			};
		case jobsConstants.APPLY_ERROR:
			return {
				...state
			};
		case jobsConstants.POST_ERROR:
			return {
				...state
			};
		case jobsConstants.UPLOAD_FILE_BEGIN:
			return {
				...state,
				isUploading: true
			};
		case jobsConstants.UPLOAD_FILE_SUCCESS:
			return {
                ...state,
                currentOffer: {
					...state.currentOffer,
					imagesNames: [...state.currentOffer.imagesNames, ...action.result]
				}
			};
		case jobsConstants.UPLOAD_FILE_ERROR:
			return {
				...state
            };
        case jobsConstants.STATISTICS_OFFERS:
            return {
                ...state,
                countOffers: action.countOffers
            };
		default:
			return state;
	}
};
