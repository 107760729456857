import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, reduxForm } from 'redux-form';
import validate from './validations/employerBaseValidation';
import asyncValidate from './validations/oldPasswordAsyncValidation';
import { renderTextField } from '../../../../helpers';

const styles = theme => ({
});

class EmployerBaseForm extends Component {

    render() {
        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit} >
                <React.Fragment>
                    <Grid container spacing={24} style={{ marginTop: 40, marginBottom: 10 }}>                        
                        <Grid item xs={12}>
                            <Field
                                disabled
                                name="email"
                                fullWidth
                                component={renderTextField}
                                label="E-mail"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="oldPassword"
                                type="password"
                                fullWidth
                                component={renderTextField}
                                label="Стара парола"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                fullWidth
                                name="newPassword"
                                type="password"
                                component={renderTextField}
                                label="Нова парола"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                fullWidth
                                name="confirmNewPassword"
                                type="password"
                                component={renderTextField}
                                label="Повторете новата парола"
                            />
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                className="next"                                
                            >
                                Редактирай
                            </Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </form>
        );
    }
}

export default withStyles(styles)(reduxForm({
    form: 'EmployerBaseForm', // a unique identifier for this form
    validate,
    asyncValidate,
    enableReinitialize: true
})(EmployerBaseForm));