import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authenticationActionCreators } from '../../actions/authentication/authenticationActionCreators';
import ForgotPasswordForm from './forms/ForgotPasswordForm';

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 500,
            marginLeft: 435,
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    stepper: {
        padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit,
    },
});

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        this.props.sendResetPasswordEmail();
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <h2>Забравена парола</h2>
                        {<ForgotPasswordForm onSubmit={this.handleSubmit} />}
                    </Paper>
                </main>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(connect(
    state => state.authentication,
    dispatch => bindActionCreators(authenticationActionCreators, dispatch)
)(ForgotPassword));