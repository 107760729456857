import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    Grid,
    Paper,
    withStyles,
    List,
    ListItem,
    ListItemText,
    LinearProgress,
    TablePagination
} from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";
import { profileActionCreators } from "../../actions/profileActionCreators";
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import { Check } from "@material-ui/icons";
import { Close } from "@material-ui/icons";
import { debounce } from 'lodash';
import moment from 'moment';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing.unit * 2,
        overflow: "hidden",
        textAlign: "justify"
    },
    coverImage: {
        width: "100%"
    },
    socialShare: {
        color: theme.palette.primary.main
    }
});

class LogActivityList extends Component {
    componentDidMount() {
        this.props.requestLogs();
    }

    pageChange = (page, count) => {
        if(this.props.page === page || page === 0) return;
        this.props.requestLogs(page, count);
    };

    search = (searchText) => {
        this.props.requestLogs(this.props.page, this.props.count, searchText);
    }

    sort = (column, direction) => {
        this.props.requestLogs(this.props.page, this.props.count, "", column, direction);
    }

    render() {
        const { classes } = this.props;
        const { page, count, logs, countLogs } = this.props;
        const columns = [
            {
                name: "LogId",
                options: { display: false }
            },
            " ",
            "Потребител",
            "Тип потребител",
            "Дата",
            "Контролер",
            "Дейност"
        ];

        const options = {
                filter: false,
                responsive: "stacked",
                serverSide: true,
                rowsPerPage: 100,
                page: page,
                count: countLogs,
                customFooter: (
                    count,
                    page,
                    rowsPerPage,
                    changeRowsPerPage,
                    changePage
                ) => (
                        <TablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page - 1}
                            backIconButtonProps={{
                                "aria-label": "Предишна страница"
                            }}
                            nextIconButtonProps={{
                                "aria-label": "Следваща страница"
                            }}
                            onChangePage={(e, pageNumber) => changePage(pageNumber + 1)}
                        />
            ),
            onTableChange: (action, tableState) => {
                console.log(action, tableState);

                switch (action) {
                    case "changePage":
                        this.pageChange(tableState.page, tableState.count);
                        break;
                    case "search":
                        if (tableState.searchText == null || tableState.searchText.length < 3) return;
                        this.search(tableState.searchText);
                        break;
                    case "sort":
                        this.sort(tableState.activeColumn, tableState.columns[tableState.activeColumn].sortDirection);
                        break;
                }
            },
        };

        return (
            <div className={classes.root} >
                <Grid container spacing={24}>
                    {this.props.isLoading && <Grid item xs={12}><LinearProgress /></Grid>}
                    {renderLogsList("Списък дейности", this.props.logs.map((x,i) => [
                        x.logId,
                        options.rowsPerPage * (this.props.page-1) + i+1,
                        x.userName,
                        x.userTypeName,
                        x.loggedOn ? moment(x.loggedOn).format("DD.MM.YYYY HH:mm") : '',
                        x.controller,
                        x.action
                    ]), columns, { ...options, count: countLogs }, this.props.isLoading)}
                </Grid>
            </div>);
    }
}

function renderLogsList(title, logs, columns, options, isLoading) {
    if (logs === undefined) {
        return;
    }

    return (
        <Grid item xs={12} style={isLoading ? {opacity: 0.5} : {opacity: 1}}>
            <MUIDataTable
                title={title}
                data={logs}
                columns={columns}
                options={options}
            />
        </Grid>
    );
}

export default withStyles(styles)(
    connect(
        state => state.profiles,
        dispatch => bindActionCreators(profileActionCreators, dispatch)
    )(LogActivityList)
);
