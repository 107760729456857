import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, reduxForm } from 'redux-form';
import validate from './validations/resetPasswordValidate';
import { renderTextField } from '../../../helpers';

const styles = theme => ({
});

class ResetPasswordForm extends Component {

    render() {
        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <React.Fragment>
                    <Grid container spacing={24} style={{ marginTop: 40, marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <p>Въведете новата си парола, след което я повторете.</p>
                        </Grid>   
                        <Grid item xs={12} md={6}>
                            <Field
                                name="password"
                                type="password"
                                component={renderTextField}
                                label="Парола"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                fullWidth
                                name="confirmPassword"
                                type="password"
                                component={renderTextField}
                                label="Повторете парола"
                            />
                        </Grid>                     
                        <Grid item xs={12} align="right">
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                className="next"
                                align="right"
                            >
                                Промяна
                            </Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </form>
        )
    }
}

export default withStyles(styles)(reduxForm({
    form: 'ResetPasswordForm', // a unique identifier for this form
    destroyOnUnmount: true, // preserve form data
    validate,
})(ResetPasswordForm));