import { menuConstants } from '../constants/menuConstants';
import { authConstants } from '../constants/authenticationConstants';
import { getLoggedUser } from '../helpers';

const initialState = {
    elements: [],
    submenuElements: [],
    articleId: 0,
    isLoading: true,
    showLogoutBtn: false
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case menuConstants.REQUEST_MENU_ELEMENTS:
            return {
                ...state,
                isLoading: false,
                elements: action.elements
            }
        case menuConstants.REQUEST_SUBMENU_ELEMENTS:
            return {
                ...state,
                isLoading: false,
                submenuElements: action.submenuElements
            }
        case authConstants.LOGIN_SUCCESS:
            return {
                ...state,
                showLogoutBtn: true
            }
        case authConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                showLogoutBtn: false
            }
        case authConstants.LOGOUT_ERROR:
            return {
                ...state
            }
        
        default: return state;
    }
}