const validate = values => {
    const errors = {}
    const requiredFields = [
        'email'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Полето е задължително'
        }
    })
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email.trim())
    ) {
        errors.email = 'Невалиден E-mail адрес'
    }
    
    return errors;
}

export default validate