import { contentConstants } from "../constants/contentConstants";
import { getLoggedUser } from "../helpers";
import { authConstants } from "../constants/authenticationConstants";

const loggedUser = getLoggedUser();

const initialState = {
	articles: [],
	news: [],
	events: [],
	articleToRead: {
		imagesNames: []
	},
	showArticleBtns:
		loggedUser && loggedUser.token && loggedUser.userType === "admin"
			? true
			: false,
	isLoading: true
};

export const reducer = (state, action) => {
	state = state || initialState;

	switch (action.type) {
		case contentConstants.REQUEST_ARTICLES:
			return {
				...state,
				isLoading: false,
				articles: action.articles
			};
		case contentConstants.REQUEST_ARTICLE_TO_READ:
			return {
				...state,
				isLoading: false,
				articleToRead: action.articleToRead
			};
		case contentConstants.REQUEST_NEWS:
			return {
				...state,
				isLoading: false,
				news: action.news
			};
		case contentConstants.REQUEST_EVENTS:
			return {
				...state,
				isLoading: false,
				events: action.events
			};
		case contentConstants.POST_SUCCESS:
			return {
				...state
			};
		case contentConstants.EDIT_SUCCESS:
			return {
				...state
			};
		case contentConstants.EDIT_ERROR:
			return {
				...state
			};
		case authConstants.LOGIN_SUCCESS:
			return {
				...state,
				showArticleBtns: true
			};
		case authConstants.LOGOUT_SUCCESS:
			return {
				...state,
				showArticleBtns: false
			};
		case authConstants.LOGOUT_ERROR:
			return {
				...state
			};
		case contentConstants.UPLOAD_FILE_SUCCESS:
			return {
				...state,
				articleToRead: {
					...state.articleToRead,
					imagesNames: [...state.articleToRead.imagesNames, ...action.result]
				}
			};
		default:
			return state;
	}
};
