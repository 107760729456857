export const contentConstants = {
    REQUEST_ARTICLES: 'REQUEST_ARTICLES',
    REQUEST_ARTICLE_TO_READ: 'REQUEST_ARTICLE_TO_READ',
    REQUEST_NEWS: 'REQUEST_NEWS',
    REQUEST_EVENTS: 'REQUEST_EVENTS',
    POST_SUCCESS: 'POST_SUCCESS',
    POST_ERROR: 'POST_ERROR',
    EDIT_SUCCESS: 'EDIT_SUCCESS',
    EDIT_ERROR: 'EDIT_ERROR',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_ERROR: 'DELETE_ERROR',
    UPLOAD_FILE_BEGIN: 'UPLOAD_FILE_BEGIN',
    UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
    UPLOAD_FILE_ERROR: 'UPLOAD_FILE_ERROR'
}