import { SubmissionError } from "redux-form";
import { authConstants } from "../../../../constants/authenticationConstants";
import { authHeader, handleResponse, handleError } from "../../../../helpers";
import { push } from "react-router-redux";
import {notify} from '../../../../helpers/notification';
import { config } from "../../../../helpers/config";

//const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const loginMiddleware = async (values, dispatch) => {
    if (values.email !== undefined && values.password !== undefined) {
        dispatch({ type: authConstants.LOGIN_BEGIN });
        const loginUrl = `${config.base}api/Authentication/Login`;
        const user = {
            email: values.email.trim(),
            password: values.password,
            type: values.loginType
        };
        return fetch(loginUrl, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(user)
		})
			.then(handleResponse)
            .then(loggedUser => {
                localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
                return loggedUser;
            })
            .then(
                loggedUser => {
                    dispatch({
                        type: authConstants.LOGIN_SUCCESS,
                        loggedUser
                    });
                    if (values.loginType === "admin") {
                        dispatch(push("/"));
                    } else if (
                        values.loginType === "student" &&
                        !loggedUser.isActivated
                    ) {
                        dispatch(push("/student/profile"));
                    }
                    dispatch(
                        notify({
                            message: "Успешен вход!",
                            status: 200
                        })
                    );
                },
                error => {

                    dispatch({
                        type: authConstants.LOGIN_ERROR,
                        error
                    });
                    dispatch(
                        notify({
                            message: error,
                            status: 400
                        })
                    );
                    throw new SubmissionError({
                        password: "Неуспешен вход",
                        _error: "Wrong password!"
                    });
                }
            );
    }
};

export default loginMiddleware;
