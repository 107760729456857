const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const asyncValidate = (values /*, dispatch */) => {
    return sleep(100).then(async () => {
        // simulate server latency
        if (values.oldPassword && values.oldPassword !== undefined) {
            const url = 'api/Authentication/CredentialsValid?email='
                + values.email
                + '&inputPassword=' + values.oldPassword;

            const response = await fetch(url);
            const fetchedUser = await response.json();

            if (fetchedUser === '') {
                throw { oldPassword: 'Текущата ви парола е грешна.' }
            }
        }
    })
}

export default asyncValidate