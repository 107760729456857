import { profilesConstants } from "../constants/profilesConstants";

const initialState = {
    isLoading: false,
    employer: {},
    student: {},
    allStudents: [],
    employers: [],
    countStudents: 0,
    page: 1,
    countEmployers: 0,
    countLogs: 0,
    logs: [],
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case profilesConstants.REQUEST_EMPLOYER_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case profilesConstants.REQUEST_EMPLOYER:
            return {
                ...state,
                isLoading: false,
                employer: action.employer
            };
        case profilesConstants.REQUEST_STUDENT_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case profilesConstants.REQUEST_STUDENT:
            return {
                ...state,
                isLoading: false,
                student: action.student
            };
        case profilesConstants.REQUEST_EMPLOYERS:
            return {
                ...state,
                employers: action.employers,
                countEmployers: action.countEmployers,
                page: action.page,
                isLoading: false
            };
        case profilesConstants.REQUEST_ALLSTUDENTS_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case profilesConstants.REQUEST_ALLSTUDENTS:
            return {
                ...state,
                allStudents: action.allStudents,
                countStudents: action.countStudents,
                page: action.page,
                isLoading: false
            };
        case profilesConstants.REQUEST_EMPLOYERS_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case profilesConstants.EDIT_STUDENT_SUCCESS:
            return {
                ...state,
                student: action.student
            };
        case profilesConstants.EDIT_SUCCESS:
            return {
                ...state
            };
        case profilesConstants.EDIT_ERROR:
            return {
                ...state
            };
        case profilesConstants.STATISTICS:
            return {
                ...state,
                countStudents: action.countStudents
            };
        case profilesConstants.STATISTICS_EMP:
            return {
                ...state,
                countEmployers: action.countEmployers
            };
        case profilesConstants.REQUEST_LOGS:
            return {
                ...state,
                logs: action.logs,
                countLogs: action.countLogs,
                page: action.page,
                isLoading: false
            };
        case profilesConstants.REQUEST_LOGS_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        default:
            return state;
    }
};
