const validate = values => {
    const errors = {}
    const requiredFields = [
        'password',
        'confirmPassword'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Полето е задължително'
        }
    });

    if (values.password !== values.confirmPassword) {
        errors.confirmNewPassword = 'Паролите нe съвпадат'
    }

    return errors
}

export default validate