export const notifierActionCreators = {
	enqueueSnackbar: notification => ({
		type: "ENQUEUE_SNACKBAR",
		notification: {
			key: new Date().getTime() + Math.random(),
			...notification
		}
	}),

	removeSnackbar: key => ({
		type: "REMOVE_SNACKBAR",
		key
	})
};
