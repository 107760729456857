let c = null;
if(process.env.NODE_ENV === 'development') {
    c = {
        base: 'https://localhost:44303/'
    };
} else {
    c = {
        base: '/'
    };
}
export const config = c;