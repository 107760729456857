import { getBase64 } from "./getBase64";

export async function getConvertedBase64Images (images) {
    let convertedImages = [];
    let imagesNames = [];

    for (let i in images) {
        let currentImage = images[i];
        let promise = getBase64(currentImage);
        let fileAsBase64 = await promise;
        imagesNames.push(currentImage.name);
        convertedImages.push(fileAsBase64.substr(fileAsBase64.indexOf(',') + 1));
    }

    return { convertedImages, imagesNames };
}