import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getLoggedUser } from './';

export function EmployerApprovedRoute({ component: Component, ...rest }) {

    const loggedUser = getLoggedUser();

    return (
        <Route {...rest} render={props => (
            (loggedUser && loggedUser.token 
                && (loggedUser.userType === 'admin' ||
                (loggedUser.userType === 'employer')))
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )} />
    )
}