
export const jobsConstants = {
    REQUEST_CATEGORIES: 'REQUEST_CATEGORIES',
    REQUEST_JOBOFFERS_BEGIN: 'REQUEST_JOBOFFERS_BEGIN',
    REQUEST_JOBOFFERS: 'REQUEST_JOBOFFERS',
    REQUEST_EMPLOYER_BEGIN: 'REQUEST_EMPLOYER_BEGIN',
    REQUEST_EMPLOYER: 'REQUEST_EMPLOYER',
    POST_SUCCESS: 'POST_SUCCESS',
    POST_ERROR: 'POST_ERROR',
    REQUEST_OFFERDETAILS_BEGIN: 'REQUEST_OFFERDETAILS_BEGIN',
    REQUEST_OFFERDETIALS: 'REQUEST_OFFERDETIALS',
    APPLY_SUCCESS: 'APPLY_SUCCESS',
    APPLY_ERROR: 'APPLY_ERROR',
    UPLOAD_FILE_BEGIN: 'UPLOAD_FILE_BEGIN',
    UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
    UPLOAD_FILE_ERROR: 'UPLOAD_FILE_ERROR',
    STATISTICS_OFFERS: 'STATISTICS_OFFERS'
}